<template>
	<div v-on="$listeners" class="catalog-card">
		<div class="catalog-card__container" :class="isMobileSmall ? 'pa-10' : 'pa-25'">
			<div class="catalog-card__photo">
				<img :src="getImgUrl(item.photo)"/>
			</div>

			<div class="catalog-card__content">
				<AppText
					size="16"
					line-height="19"
					weight="600"
					class="font_Hsemibold mb-5"
				>
					{{ item.name | translate }}
				</AppText>
				<AppText
					size="14"
					line-height="17"
					weight="500"
					class="font_Hmedium color-text-light"
				>Кол-во товаров: {{ item.count }}
				</AppText>
			</div>
		</div>
	</div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";

export default {
	name: "pagination",
	components: {
		AppText,
	},
	props: {
		item: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN
		};
	},
	methods: {
		getImgUrl(imgUrl) {
			if (imgUrl != null) {
				return this.adminUrl + imgUrl;
			} else {
				return imgUrl
			}
		}
	},
	computed: {},
};
</script>

<style lang='scss' scoped>

.catalog-card {
	border-radius: 10px;
	// overflow: hidden;
	transition: 0.3s;
	background-color: #fff;

	&:hover {
		box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
		border-color: transparent;
	}

	&__container {
		display: flex;
	}

	&__photo {
		width: 100%;
		height: 65px;
		max-width: 65px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-right: 25px;
		min-width: 65px;

		img {
			width: 100%;
			height: 100%;
			//max-height: 65px;
			//max-width: 65px;
			object-fit: cover;
			cursor: pointer;
		}

		p.title {
			position: absolute;
			top: 15px;
			right: 15px;
			border-radius: 3px;
			padding: 5px;
			font-size: 12px;
			font-weight: bolder;
		}
	}
}
</style>