<template>
	<div class="">
		<div class="bg-white">
			<div class="container top">
				<div class="page-head py-30">
					<div class="content-info">
						<div class="content-info__item">
							<div class="mb-10">
								<AppText size="14" line-height="16" weight="600"
									>{{ $t("numberOfBidsSubmitted") | toUpperCase }}:
								</AppText>
							</div>
							<div class="d-flex">
								<div class="d-flex flex-column">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600">
											{{ shopOffertDealsInfo.viszaden | price }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("perday") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500">
										{{ shopOffertDealsInfo.viszadensumm | price }} UZS
									</AppText>
								</div>
								<div class="d-flex flex-column ml-40">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600"
											>{{ shopOffertDealsInfo.viszagod }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("yearly") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500"
										>{{ shopOffertDealsInfo.viszagodsumm | price }} UZS
									</AppText>
								</div>
							</div>
						</div>
						<div class="content-info__item">
							<!-- <div class="mb-10">
								<AppText size="14" line-height="16" weight="600"
									>{{ $t("dealAmount") | toUpperCase }}:
								</AppText>
							</div>
							<div class="d-flex">
								<div class="d-flex flex-column">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600">
											{{ shopOffertDealsInfo.dealzaden }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("perday") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500">
										{{ shopOffertDealsInfo.dealzadensumm | price }} UZS
									</AppText>
								</div>
								<div class="d-flex flex-column ml-40">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600">
											{{ shopOffertDealsInfo.dealzagod }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("yearly") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500"
										>{{ shopOffertDealsInfo.dealzagodsumm | price }} UZS
									</AppText>
								</div>
							</div> -->
						</div>
						<div class="content-infoSupport">
							<span class="content-infoSupportFirst">
								<router-link to="/product-list/other-products">
									<div>
										<div class="nd_shop_block mr-15">
											<img src="@/assets/icons/shop_img1.svg" class="mr-15" alt="" />
											<AppText size="14" line-height="17" weight="500">
												{{ $t("goodsOfOrganizationsRelatedPersonsWithDisabilities") }} </AppText
											>&nbsp;
										</div>
									</div>
								</router-link>
							</span>
							<span>
								<router-link to="/product-list/other-products">
									<div>
										<div class="nd_shop_block">
											<img src="@/assets/icons/shop_img2.svg" class="mr-15" alt="" />
											<AppText size="14" line-height="17" weight="500">
												{{ $t("electricalEquipmentUzeltekhsanoat") }} </AppText
											>&nbsp;
										</div>
									</div>
								</router-link>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="section pt-30 bg-primary">
			<div class="container">
				<div class="section__wrapper pb-60">
					<div class="section__top d-flex flex-column flex-nowrap">
						<header class="auction-header w-100 mb-20" :class="isMobileMedium ? 'flex-wrap' : ''">
							<app-text
								tag="h1"
								size="26"
								line-height="34"
								weight="600"
								:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
								class="mr-30"
							>
							</app-text>
						</header>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import "./../product-list.scss";
import ProductListStatistics from "@/components/pages/ProductList/ProductListStatistics";
import AppText from "@/components/shared-components/AppText";
import AppButton from "@/components/shared-components/AppButton";
import DynamicIcon from "@/components/shared-components/DynamicIcon";
import AppInput from "@/components/shared-components/AppInput";
import BlockWrap from "@/components/shared-components/BlockWrap";
import AppDropdown from "@/components/shared-components/AppDropdown";
import AppContentCard from "@/components/shared-components/AppContentCard";
import FormLabel from "@/components/shared-components/FormLabel";
import AppButtonA from "@/components/shared-components/AppButtonA";
import AppProductCard from "@/components/shared-components/AppProductCard";
import ShopService from "@/services/api/shop-service";
import ReferenceService from "@/services/api/reference-service";
import AppPagination from "@/components/shared-components/AppPagination";
import Pagination from "@/components/shared-components/Pagination";
import { ModelListSelect } from "vue-search-select";
import CatalogCard from "@/components/shared-components/CatalogCard";
export default {
	name: "index",
	components: {
		AppProductCard,
		AppButtonA,
		FormLabel,
		AppContentCard,
		AppDropdown,
		BlockWrap,
		AppInput,
		DynamicIcon,
		AppButton,
		AppText,
		ProductListStatistics,
		Pagination,
		ModelListSelect,
		CatalogCard,
		AppPagination,
	},
	data() {
		return {
			list: [],
			query: {
				CategoryId: null,
				FirstTnvedCategoryId: null,
				ProductName: null,
				CountryId: null,
				Manufacturer: null,
				From: null,
				To: null,
				IsLocalManufacturer: null,
				CompanyTnVed: null,
				skip: 0,
				take: 12,
				filter: null,
			},
			total: 0,
			cleanQuery: {},
			categoryList: [],
			countries: [],
			filterToggle: false,
			isOpenFilterPanel: false,
			tnvedList: [],
			shopOffertDealsInfo: {},
			allTnVedCategory: [],
			route: this.$route,
		};
	},
	created() {
		this.getOffertDealsInfo();
	},
	methods: {
		getOffertDealsInfo() {
			this.$store.commit("setLoadingStatus", true);
			this.$api
				.get(`/api-client/Client/ShopOffertDealsCount`)
				.then(
					(response) => {
						this.shopOffertDealsInfo = response.data.result;
					},
					(error) => {}
				)
				.finally(() => {
					this.$store.commit("setLoadingStatus", false);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.content-infoSupport {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	.content-infoSupportFirst {
		grid-column: 2 / 3;
	}
}
.page-head {
	width: 100%;

	.content-inner {
		display: flex;
	}
}

.content-info {
	display: grid;
	grid-template-columns: 310px 150px 1fr !important;
	grid-column-gap: 80px;

	&__item {
		display: flex;
		flex-direction: column;
		min-width: 200px;
	}
}

.auction-filter {
	// flex-wrap: wrap;
}

// .bw > .bw__cn {
//   flex-direction: column;
// }
.container.top {
}

.page-head {
	width: 100%;

	.content-inner {
		display: flex;
	}
}

.content-info {
	&__item {
		display: flex;
		flex-direction: column;
		min-width: 200px;
	}
}

.auction-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__input {
		margin-right: 15px;
		width: 250px;
		border-style: none;
	}

	&__button {
		span {
			vertical-align: baseline;
		}

		svg {
			margin-left: -8px;
		}
	}
}

.auction-filter {
	display: flex;
	width: 100%;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: calc(100% - 200px);
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		width: 200px;
	}

	&__buttons {
		display: flex;
	}
}

.nd_shop_block {
	display: flex;
	align-items: center;
	color: #0dc9b4;
	border: 1px solid #48c6ef;
	border-radius: 12px;
	background: #fff;
	padding: 10px 12px;
}

@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>

