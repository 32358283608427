<template>
  <div class="w-100 bg-secondary color-white py-20">
    <div class="container">

      <div class="d-flex w-100 justify-space-between"
           :class="isMobile ? 'd-block' : ''"
      >

        <div class="my-20"
             :class="[isMobileMedium ? '' : 'd-flex', isMobileMedium ? '' : 'flex-wrap']"
        >
          <AppText size="14"
                   line-height="19"
                   weight="500"
                   class="mr-50 my-10"
          >{{$t('numberOfBidsSubmitted')}}:
          </AppText>

          <div :class="isMobileSmall ? '' : 'd-flex'">
            <div class="mr-50 my-10">
              <AppText size="14"
                       line-height="19"
                       weight="500"
                       class="mb-5 text-bold">123 {{$t('perday')}}
              </AppText>
              <AppText class="color-main"
                       weight="500"
              >0
              </AppText>
            </div>
            <div class="my-10 my-10">
              <AppText size="14"
                       line-height="19"
                       class="mb-5 text-bold"
                       weight="500"
              >42 070 за год
              </AppText>
              <AppText class="color-main"
                       weight="500"
              >11 796 685 975,64
              </AppText>
            </div>
          </div>
        </div>

        <div class="my-20"
             :class="[isMobileSmall ? '' : 'd-flex', isMobileSmall ? '' : 'flex-wrap']"
        >
          <AppText
                  size=" 14
        "
                  line-height="19"
                  weight="500"
                  class="mr-50 my-10"
          >{{$t('dealAmount')}}:
          </AppText>

          <div :class="isMobileSmall ? '' : 'd-flex'">
            <div class="mr-50 my-10">
              <AppText size="14"
                       line-height="19"
                       class="mb-5 text-bold"
                       weight="500"
              >156 {{$t('perday')}}
              </AppText>
              <AppText class="color-main"
                       weight="500"
              >0
              </AppText>
            </div>
            <div class="my-10">
              <AppText size="14"
                       line-height="19"
                       class="mb-5 text-bold"
                       weight="500"
              >50 070 за год
              </AppText>
              <AppText class="color-main"
                       weight="500"
              >11 796 685 975,64
              </AppText>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
    import BlockWrap from "../../shared-components/BlockWrap";
    import AppText from "../../shared-components/AppText";

    export default {
        name: "ProductListStatistics",
        components: {AppText, BlockWrap}
    }
</script>

<style scoped>

</style>
